/* YourComponent.css */

/* Position the component at the top */
.top-0 {
    top: 0;
  }
  
  /* Transition the opacity and background-color properties for smooth animation */
  .transition-opacity {
    transition-property: opacity, background-color;
  }
  
  .duration-500 {
    transition-duration: 500ms;
  }
  
  /* Define the visible and hidden states using opacity and background-color */
  .opacity-0 {
    opacity: 0;
    pointer-events: none; /* Disable pointer events when hidden */
    background-color: rgba(255, 255, 255, 0); /* Transparent background color */
  }
  
  .opacity-100 {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent background color */
  }